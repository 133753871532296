<template>
  <div class="body-bg">
    <Header
      :menuStyle="menuStyle"
      :activeBtn="activeId"
      :client="flag"
    ></Header>
    <div class="bg">
      <div class="content" ref="content" id="content">
        <div class="head" ref="head" id="head">
          <div class="container">
            <div class="remark">
              <h1 class="h1-box">{{ $t("home.headTitle") }}</h1>
              <p class="p-box">
                {{ $t("home.headDesc") }}
              </p>
              <ul class="website">
                <li>
                  <a href="https://t.me/fura_community" target="_blank"
                    ><img src="../assets/img/telegram.png" alt="telegram"
                  /></a>
                </li>
                <li>
                  <a href="https://github.com/fura-protocol" target="_blank"
                    ><img
                      class="github"
                      src="../assets/img/github.png"
                      alt="github"
                  /></a>
                </li>
                <li>
                  <a href="https://twitter.com/fura_protocol" target="_blank"
                    ><img src="../assets/img/twitter.png" alt="twitter"
                  /></a>
                </li>
              </ul>
            </div>
            <!-- <div v-if="flag == 'moblie'" class="pic">
              <img src="../assets/img/head1.png" alt="top-head" />
            </div> -->
          </div>
        </div>
        <div class="bg-wave">
          <div class="container">
            <div class="apis" ref="apis" id="apis">
              <div class="remark">
                <div class="title">{{ $t("home.problemTitle") }}</div>
                <div v-if="flag == 'pc'" class="p-box">
                  <p>{{ $t("home.problemDesc1") }}</p>
                  <p class="p2">{{ $t("home.problemDesc2") }}</p>
                </div>
                <div v-if="flag == 'moblie'" class="p-box">
                  <p>{{ $t("home.problemDesc1") }}</p>
                  <p class="p2">{{ $t("home.problemDesc2") }}</p>
                </div>
              </div>
              <div class="pic">
                <img src="../assets/img/tps.png" alt="apis-head" />
              </div>
            </div>
          </div>
        </div>
        <div class="protocol" ref="protocol" id="protocol">
          <div class="container">
            <div class="title">{{ $t("home.protocolTitle") }}</div>
            <p class="desc">
              {{ $t("home.protocolDesc1") }}
            </p>
            <div v-if="flag == 'pc'" class="pic">
              <img src="../assets/img/protocol.svg" alt="" />
            </div>
            <div v-else class="pic">
              <img src="../assets/img/protocol-m.svg" alt="" />
            </div>
            <p>
              {{ $t("home.protocolDesc2") }}
            </p>
          </div>
        </div>
        <div class="blockchain" ref="blockchain">
          <div class="container">
            <div class="title">{{ $t("home.dataIndexTitle") }}</div>
            <div class="blockchain-box">
              <div class="remark">
                <p>
                  {{ $t("home.dataIndexDesc1") }}
                </p>
                <p class="p1">
                  {{ $t("home.dataIndexDesc2") }}
                </p>
              </div>
              <div class="pic">
                <img src="../assets/img/data.png" alt="tps" />
              </div>
            </div>
          </div>
        </div>
        <div class="indexing" ref="indexing" id="indexing">
          <div class="container">
            <div class="title">{{ $t("home.indexing") }}</div>
            <div v-if="flag == 'pc'">
              <ul>
                <li>
                  <img src="../assets/img/loop.png" alt="" />
                  <p>{{ $t("home.indexingDesc1") }}</p>
                </li>
                <li>
                  <img src="../assets/img/loop.png" alt="" />
                  <p>{{ $t("home.indexingDesc2") }}</p>
                </li>
                <li>
                  <img src="../assets/img/loop.png" alt="" />
                  <p>{{ $t("home.indexingDesc3") }}</p>
                </li>
                <li>
                  <img src="../assets/img/loop.png" alt="" />
                  <p>{{ $t("home.indexingDesc4") }}</p>
                </li>
              </ul>
            </div>
            <div v-else>
              <ul>
                <li>
                  <img src="../assets/img/loop.png" alt=""/>
                  <p>{{ $t("home.indexingDesc1") }}</p>
                </li>
                <li>
                  <img src="../assets/img/loop.png" alt=""/>
                  <p>{{ $t("home.indexingDesc2") }}</p>
                </li>
              </ul>
              <ul>
                <li>
                  <img src="../assets/img/loop.png" alt=""/>
                  <p>{{ $t("home.indexingDesc3") }}</p>
                </li>
                <li>
                  <img src="../assets/img/loop.png" alt=""/>
                  <p>{{ $t("home.indexingDesc4") }}</p>
                </li>
              </ul>
            </div>
            <div v-if="flag == 'pc'" class="pic">
              <img src="../assets/img/complicated.svg" alt="" />
            </div>
            <div v-else class="pic">
              <img src="../assets/img/complicated-m.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="product" ref="product" id="product">
          <div class="container">
            <div class="title">{{ $t("home.product") }}</div>
            <ul>
              <li>
                <div class="icon">
                  <img src="../assets/img/core1.png" alt="" />
                </div>
                <h1>{{ $t("home.productSub1") }}</h1>
                <h2>{{ $t("home.productSubDesc") }}</h2>
              </li>
              <li>
                <div class="icon">
                  <img src="../assets/img/core2.svg" alt="" />
                </div>
                <h1>{{ $t("home.productSub2") }}</h1>
                <h2>
                  {{ $t("home.productSubDesc2") }}
                </h2>
              </li>
            </ul>
            <ul>
              <li>
                <div class="icon">
                  <img src="../assets/img/core3.svg" alt="" />
                </div>
                <h1>{{ $t("home.productSub3") }}</h1>
                <h2>{{ $t("home.productSubDesc3") }}</h2>
              </li>
              <li>
                <div class="icon">
                  <img src="../assets/img/core4.svg" alt="" />
                </div>
                <h1>{{ $t("home.productSub4") }}</h1>
                <h2>
                  {{ $t("home.productSubDesc4") }}
                </h2>
              </li>
            </ul>
          </div>
        </div>
        <!--
        <div class="architecture" ref="architecture" id="architecture">
          <div class="container">
            <div class="title">{{ $t("home.architecture") }}</div>
            <div v-if="flag == 'pc'" class="pic">
              <img src="../assets/img/architecture.png" alt="Architecture" />
            </div>
            <div v-else class="pic">
              <img src="../assets/img/architecture-m.png" alt="Architecture" />
            </div>
            <div class="architecture-box">
              <div class="p1">
                {{ $t("home.architectureDesc1") }}
              </div>
              <div class="arrow">
                <img src="../assets/img/arrow.png" alt="frua" />
              </div>
              <div class="p1">
                {{ $t("home.architectureDesc2") }}
              </div>
              <div class="arrow">
                <img src="../assets/img/arrow.png" alt="frua" />
              </div>
              <div class="p1">
                {{ $t("home.architectureDesc3") }}
              </div>
            </div>
          </div>
        </div>
        <div class="service" ref="service" id="service">
          <div class="container">
            <div class="title">{{ $t("home.service") }}</div>
            <p>
              {{ $t("home.serviceDesc") }}
            </p>
            <div v-if="flag == 'pc'" class="pic">
              <img src="../assets/img/service.png" alt="" />
            </div>
            <div v-else class="pic">
              <img src="../assets/img/service-m.png" alt="" />
            </div>
          </div>
        </div>
        -->
        <div class="bg2">
          <div class="advantages" ref="advantages" id="advantages">
            <div class="container">
              <div class="title">{{ $t("home.adv") }}</div>
              <ul class="advantages-box">
                <li>
                  <img class="icon" src="../assets/img/api1.svg" alt="" />
                  <h1>80,828,504</h1>
                  <h2>{{ $t("home.adv1") }}</h2>
                </li>
                <li>
                  <img class="icon" src="../assets/img/api2.svg" alt="" />
                  <h1>44,958,760</h1>
                  <h2>{{ $t("home.adv2") }}</h2>
                </li>
                <li>
                  <img class="icon" src="../assets/img/api3.svg" alt="" />
                  <h1>88,640,012</h1>
                  <h2>{{ $t("home.adv3") }}</h2>
                </li>
              </ul>
            </div>
          </div>
          <div class="stack" ref="stack">
            <div class="container">
              <div class="title">{{ $t("home.stack") }}</div>
              <div class="stack-box">
                <div class="remark-box">
                  <div class="remark">
                    <p>
                      {{ $t("home.stackSub1") }}
                    </p>
                    <p class="p1">
                      {{ $t("home.stackDesc1") }}
                    </p>
                  </div>
                  <div class="remark">
                    <p>
                      {{ $t("home.stackSub2") }}
                    </p>
                    <p class="p1">
                      {{ $t("home.stackDesc2") }}
                    </p>
                  </div>
                  <div class="remark">
                    <p>
                      {{ $t("home.stackSub3") }}
                    </p>
                    <p class="p1">
                      {{ $t("home.stackDesc3") }}
                    </p>
                  </div>
                  <div class="remark">
                    <p>
                      {{ $t("home.stackSub4") }}
                    </p>
                    <p class="p1">
                      {{ $t("home.stackDesc4") }}
                    </p>
                  </div>
                </div>
                <div class="pic">
                  <img src="../assets/img/3ds.svg" alt="tps" />
                </div>
              </div>
            </div>
          </div>
          <div class="roadmap" ref="roadmap" id="roadmap">
            <div class="container">
              <div class="title">{{ $t("home.roadMap") }}</div>
              <ul>
                <li>
                  <img src="../assets/img/qbg.png" alt="" />
                  <div class="sub-title">2021 Q3</div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF1Desc1") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF1Desc2") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF1Desc3") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF1Desc4") }}
                    </h2>
                  </div>
                </li>
                <li>
                  <img src="../assets/img/qbg.png" alt="" />
                  <div class="sub-title">2021 Q4</div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF2Desc1") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF2Desc2") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF2Desc3") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF2Desc4") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF2Desc5") }}
                    </h2>
                  </div>
                </li>
                <li>
                  <img src="../assets/img/qbg.png" alt="" />
                  <div class="sub-title">2022 Q1</div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF3Desc1") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF3Desc2") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF3Desc3") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF3Desc4") }}
                    </h2>
                  </div>
                  <!-- <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF3Desc5") }}
                    </h2>
                  </div> -->
                </li>
                <li>
                  <img src="../assets/img/qbg.png" alt="" />
                  <div class="sub-title">2022 Q2</div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF4Desc1") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF4Desc2") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF4Desc3") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF4Desc4") }}
                    </h2>
                  </div>
                  <div class="phase">
                    <img src="../assets/img/loop.png" alt="" />
                    <h2>
                      {{ $t("home.roadMapF4Desc5") }}
                    </h2>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="map"><img src="../assets/img/map.png" alt="" /></div> -->
        <!--
        <div class="footer-nav">
          <ul class="ul-nav hidden-mobile">
            <li>
              <h1>About Fura</h1>
            </li>
            <li>
              <h1>Navigation</h1>
              <ul class="ul-li-nav">
                <li
                  v-for="item in menuList"
                  :key="item.id"
                  :class="{ active: activeId == item.id }"
                  v-scroll-to="{ el: '#' + item.id, offset: myOffset }"
                >
                  {{ item.name }}
                </li>
              </ul>
            </li>
            <li>
              <h1>Contact</h1>
            </li>
          </ul>
          <ul class="ul-nav-m hidden-pc">
            <li>
              <h1>Navigation</h1>
              <ul class="ul-li-nav">
                <li
                  v-for="item in menuList"
                  :key="item.id"
                  :class="{ active: activeId == item.id }"
                  v-scroll-to="{ el: '#' + item.id, offset: myOffset }"
                >
                  {{ item.name }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
        -->
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: { Header, Footer },
  data() {
    return {
      scroll: "",
      menuStyle: false,
      activeId: "",
      menuList: [
        // { name: "The problem", id: "head" },
        { name: "Product", id: "product" },
        { name: "Architecture", id: "architecture" },
        // { name: "Service Architecture", id: "service" },
        { name: "Advantages", id: "advantages" },
        { name: "Roadmap", id: "roadmap" },
      ],
      flag: null,
    };
  },
  computed: {
    myOffset: function () {
      return this.flag == "pc" ? -160 : -60;
    },
  },
  methods: {
    //handleNavigation(id) {
    // this.$refs[id].scrollIntoView({
    //   behavior: "smooth", // 平滑过渡
    //   block: "start", // 上边框与视窗顶部平齐。默认值
    // });
    // },
    menu() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.menuStyle = this.scroll > 130 ? true : false;

      if (this.flag == "pc") {
        switch (true) {
          case this.scroll <= 1667:
            this.activeId = "head";
            break;
          case this.scroll > 1667 && this.scroll <= 2402:
            this.activeId = "product";
            break;
          case this.scroll > 2402 && this.scroll <= 3231:
            this.activeId = "architecture";
            break;
          case this.scroll > 3231 && this.scroll <= 4207:
            this.activeId = "service";
            break;
          case this.scroll > 4207 && this.scroll <= 4818:
            this.activeId = "advantages";
            break;
          case this.scroll > 4818:
            this.activeId = "roadmap";
            break;
          default:
            break;
        }
      } else if (this.flag == "moblie") {
        switch (true) {
          case this.scroll <= 1366:
            this.activeId = "head";
            break;
          case this.scroll > 1366 && this.scroll <= 2016:
            this.activeId = "product";
            break;
          case this.scroll > 2016 && this.scroll <= 2550:
            this.activeId = "architecture";
            break;
          case this.scroll > 2550 && this.scroll <= 3019:
            this.activeId = "service";
            break;
          case this.scroll > 3019 && this.scroll <= 3863:
            this.activeId = "advantages";
            break;
          case this.scroll > 3863:
            this.activeId = "roadmap";
            break;
          default:
            break;
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.menu);
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    this.flag = flag == null ? "pc" : "moblie";
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.body-bg {
  background: #0f0f16;
}
@media screen and (min-width: 1025px) {
  .bg {
    // background: url("../assets/img/head-bg.png") no-repeat center 0;
    // background-size: 100%;
    background: #0a0f15;
  }
  .content {
    height: 100%;
    background: url("../assets/img/head-bg.png") no-repeat center 0;
    background-size: 100%;
    padding-top: 88px;
    overflow-y: scroll;
  }
  .title {
    width: 530px;
    font-size: 36px;
    font-family: ANB;
    font-weight: bold;
    color: #ace8f2;
    line-height: 49px;
  }
  p {
    font-size: 21px;
    line-height: 29px;

    &.p1 {
      margin-top: 40px;
    }
  }
  .bg-wave {
    background: url("../assets/img/breakline.png") no-repeat center 0;
    background-size: 100%;
    padding-top: 208px;
  }
  .head {
    color: #fff;
    height: 80vh;
    position: relative;
    background: url("../assets/img/head-new-bak.png") no-repeat right bottom;
    background-size: 50%;
    // background-size: cover;

    .remark {
      position: absolute;
      width: 48%;
      bottom: 10%;
      padding-left: 48px;
      h1 {
        font-size: 58px;
        font-family: "ANB";
        line-height: 79px;
      }

      .h1-box {
        margin-top: 40px;
        font-weight: bold;
      }

      .p-box {
        margin-top: 19px;
      }

      .website {
        display: flex;
        align-items: center;
        margin-top: 48px;

        li {
          cursor: pointer;
          width: 25px;
          height: 21px;
          margin: 0 32px 0 0;
          .github {
            width: 23px;
          }
        }
      }
    }
    // .pic {
    //   width: 50%;
    //   height: 100%;
    // }
  }
  .apis {
    margin-top: 90px;
    margin-bottom: 90px;
    margin-left: 48px;
    margin-right: 48px;
    display: flex;
    justify-content: space-between;
    .remark {
      width: 48%;
      // margin-right: 38px;

      h1 {
        font-size: 58px;
        font-family: "ANB";
      }

      .p-box {
        margin-top: 82px;
      }

      .p2 {
        margin-top: 30px;
      }
    }
    .pic {
      margin-top: 135px;
      width: 40%;
      height: 100%;
    }
  }
  .protocol {
    margin-top: 200px;
    .title {
      margin-left: 48px;
      margin-right: 48px;
    }
    .desc {
      font-size: 30px;
    }
    p {
      margin-top: 80px;
      margin-left: 48px;
      margin-right: 48px;
      font-size: 20px;
      font-family: ANM;
      font-weight: 500;
      line-height: 25px;
      text-align: center;
    }
    .pic {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      // width: 1200px;
      // height: 497px;
      // width: 100%;
      // height: 100%;
      img {
        width: 60%;
      }
    }
  }
  .blockchain {
    .title {
      margin-left: 48px;
      margin-right: 48px;
      margin-top: 120px;
    }
    .blockchain-box {
      display: flex;
      justify-content: space-between;
      margin-left: 48px;
    }

    .remark {
      padding-top: 80px;
      width: 594px;
    }

    .pic {
      width: 35%;
      margin-right: 48px;
    }
  }
  .indexing {
    margin-top: 200px;
    .title {
      margin-left: 48px;
      margin-right: 48px;
    }
    ul {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      margin-left: 48px;
      margin-right: 48px;
    }

    li {
      display: flex;
      align-items: center;

      img {
        width: 19px;
        height: 19px;
      }

      p {
        margin-left: 20px;
        font-size: 18px;
        font-family: ANM;
        font-weight: 500;
        line-height: 25px;
      }
    }
    .pic {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      // width: 1200px;
      // height: 497px;
      // width: 100%;
      // height: 100%;
      img {
        width: 93%;
      }
    }
  }
  .product {
    .title {
      margin-top: 200px;
      margin-left: 48px;
      margin-right: 48px;
      margin-bottom: 130px;
    }
    ul {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      margin-left: 48px;
      margin-right: 48px;
    }

    li {
      width: 630px;
      height: 416px;
      padding: 116px 30px 0 30px;
      box-sizing: border-box;
      position: relative;
      border: 1px solid #7884a8;
      border-radius: 8px;
      background: linear-gradient(360deg, #1c1e2b 0%, #1a1c2c 100%);

      &:hover {
        transform: translateY(-10px);
        transition: all 0.5s ease;
      }

      .icon {
        position: absolute;
        width: 92px;
        height: 92px;
        top: -42px;
        left: 36px;
      }

      h1 {
        font-family: ANB;
        font-size: 18px;
        color: #ffffff;
        line-height: 25px;
      }

      h2 {
        margin-top: 40px;
        font-size: 18px;
        font-family: ANM;
        font-weight: 500;
        color: #9aaed1;
        line-height: 25px;
      }
    }
  }
  .architecture {
    margin-top: 200px;
    .title {
      margin-left: 48px;
      margin-right: 48px;
    }
    .pic {
      margin-top: 80px;
      margin-left: 48px;
      margin-right: 48px;
      // width: 1200px;
      // height: 245px;
      // width: 100%;
      // height: 100%;
    }
    &-box {
      margin-top: 105px;
      margin-left: 48px;
      margin-right: 48px;
      display: flex;
      justify-content: space-between;
    }
    .p1 {
      width: 348px;
      font-size: 18px;
      font-family: ANM;
      font-weight: 500;
      color: #9aaed1;
      line-height: 25px;
    }
    .arrow {
      width: 80px;
      text-align: center;

      img {
        width: 24px;
        height: 10px;
        margin: 8px auto;
      }
    }
  }
  .service {
    margin-top: 200px;
    .title {
      margin-left: 48px;
      margin-right: 48px;
    }
    p {
      margin-top: 80px;
      margin-left: 48px;
      margin-right: 48px;
      font-size: 18px;
      font-family: ANM;
      font-weight: 500;
      color: #9aaed1;
      line-height: 25px;
    }

    .pic {
      margin-top: 100px;
      margin-left: 48px;
      margin-right: 48px;
      // width: 1200px;
      // height: 497px;
      // width: 100%;
      // height: 100%;
    }
  }
  .advantages {
    margin-top: 200px;
    .title {
      margin-left: 48px;
      margin-right: 48px;
    }

    &-box {
      margin-top: 120px;
      margin-left: 48px;
      margin-right: 48px;
      display: flex;
      justify-content: space-between;
    }
    li {
      width: 410px;
      height: 392px;
      border: 1px solid #7884a8;
      border-radius: 4px;
      background: linear-gradient(360deg, #1c1e2b 0%, #1a1c2c 100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;

      &:hover {
        background: rgba(131, 159, 231, 0.2);
        transition: background 0.3s ease;
      }

      .icon {
        width: 76px;
        height: 76px;
        margin-bottom: 28px;
        margin-top: 40px;
      }

      h1 {
        text-align: center;
        font-size: 56px;
        font-family: ANM;
        font-weight: 600;
        color: #fff;
        line-height: 25px;
        margin-top: 64px;
      }

      h2 {
        text-align: center;
        font-size: 14px;
        font-family: ANM;
        font-weight: 600;
        color: #9aaed1;
        line-height: 25px;
        margin-top: 48px;
      }
    }
  }
  .stack {
    .title {
      margin-left: 48px;
      margin-right: 48px;
      margin-top: 120px;
    }
    .stack-box {
      display: flex;
      justify-content: space-between;
      margin-left: 48px;
    }
    .remark-box {
      display: flex;
      flex-direction: column;
      .remark {
        padding-top: 70px;
        width: 594px;

        p {
          background: linear-gradient(to right, #00ffbc, #2073ff);
          -webkit-background-clip: text;
          color: transparent;
        }
        .p1 {
          margin-top: 20px;
          font-size: 18px;
          color: #fff;
        }
      }
    }

    .pic {
      width: 30%;
      margin-right: 48px;
    }
  }
  .roadmap {
    margin-top: 200px;
    .title {
      margin-left: 48px;
      margin-right: 48px;
    }
    ul {
      margin-top: 160px;
      margin-left: 48px;
      margin-right: 48px;
      display: flex;
      justify-content: space-between;
    }
    li {
      position: relative;
      width: 310px;
      .phase {
        display: flex;
        img {
          width: 10px;
          height: 10px;
          margin-top: 16px;
        }
        h1 {
          margin-top: 24px;
          margin-left: 18px;
          font-size: 30px;
          font-family: ANB;
          color: #ffffff;
          line-height: 41px;
        }
        h2 {
          width: 194px;
          margin-top: 8px;
          margin-left: 16px;
          font-size: 14px;
          font-family: ANM;
          font-weight: 500;
          color: #9aaed1;
          line-height: 29px;
        }
      }
    }
    img {
      width: 310px;
      height: 210px;
    }
    .sub-title {
      height: 48px;
      line-height: 48px;
      position: absolute;
      top: 0px;
      right: 50px;
      font-size: 24px;
      font-family: ANB;
      color: #cddaff;
    }
  }
  .bg2 {
    background: radial-gradient(rgba(18, 38, 57, 0.8) 0%, #0a1016 70%);
  }
  .map {
    width: 100%;
    height: 448px;
    margin: 50px 0 100px;
  }
  .footer-nav {
    width: 890px;
    margin: 40px auto;

    .ul-nav {
      display: flex;
      justify-content: space-between;
    }

    h1 {
      font-size: 18px;
      font-family: ANB;
      color: #ffffff;
    }

    .ul-li-nav {
      margin-top: 34px;
      li {
        margin-bottom: 20px;
        font-size: 14px;
        font-family: ANM;
        font-weight: 500;
        color: #9aaed1;
        &:hover {
          color: #fff;
          cursor: pointer;
          text-shadow: 0px 0px 15px #4790f0;
        }

        &.active {
          color: #fff;
          text-shadow: 0px 0px 15px #4790f0;
        }
      }
    }
  }
}
@media screen and (max-width: 428px) {
  // .bg {
  //   background: url("../assets/img/head1.png") no-repeat right 0;
  //   background-size: 60%;
  // }
  .content {
    height: 100%;
    background: url("../assets/img/head-bg-m.png") no-repeat center 0;
    background-size: 100%;
    padding-top: 80px;
    overflow-y: scroll;
  }
  .title {
    width: 100%;
    font-size: 28px;
    font-family: ANB;
    color: #ace8f2;
    line-height: 32px;
  }
  p {
    font-size: 16px;
    line-height: 16px;

    &.p1 {
      margin-top: 16px;
    }
  }
  .bg-wave {
    background: url("../assets/img/breakline.png") no-repeat 0 0;
    background-size: 100%;
    padding-top: 96px;
  }
  .head {
    margin-top: 10px;
    // margin-left: 16px;
    // display: flex;
    // justify-content: space-between;
    background: url("../assets/img/head-new-m.png") no-repeat right top 74px;
    background-size: 100%;
    color: #fff;
    height: 80vh;
    position: relative;
    .remark {
      position: absolute;
      width: 80%;
      bottom: 8%;
      margin-left: 16px;
      h1 {
        font-size: 36px;
        font-family: "ANB";
        line-height: 48px;
      }
      .h1-box {
        font-weight: bold;
      }
      .p-box {
        font-size: 18px;
        width: 100%;
        margin-top: 30px;
      }
      .website {
        display: flex;
        align-items: center;
        margin-top: 30px;
        li {
          cursor: pointer;
          width: 25px;
          height: 21px;
          margin: 0 16px 0 0;
          .github {
            width: 23px;
          }
        }
      }
    }
    .pic {
      width: 80%;
      height: 100%;
      margin-top: 300px;
    }
  }
  .apis {
    margin-top: 10px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .remark {
      width: 100%;
      margin-top: 25px;
      margin-right: 0px;

      h1 {
        font-size: 38px;
        font-family: "ANB";
        line-height: 44px;
      }

      .p2 {
        margin-top: 30px;
      }

      .p-box {
        margin-top: 32px;

        p {
          line-height: 20px;
        }
      }
    }
    .pic {
      width: 100%;
      height: 100%;
      margin-top: 50px;
      margin-bottom: 66px;
    }
  }
  .protocol {
    padding: 50px 16px;
    p {
      margin-top: 32px;
      font-size: 14px;
      font-family: ANM;
      font-weight: 500;
      color: #ffffff;
      line-height: 19px;
    }

    .pic {
      margin-top: 32px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .blockchain {
    padding: 50px 16px;
    .blockchain-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 36px;
    }

    .remark {
      width: 100%;

      p {
        line-height: 20px;
      }
    }

    .pic {
      width: 100%;
      height: 100%;
      margin-top: 44px;
    }
  }
  .indexing {
    padding: 50px 16px;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 20px;
    }

    li {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-right: 10px;

      img {
        width: 10px;
        height: 10px;
      }

      p {
        margin-left: 10px;
        font-size: 14px;
        font-family: ANM;
        font-weight: 500;
      }
    }
    .pic {
      margin-top: 32px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .product {
    padding: 50px 16px;
    ul {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    li {
      height: 290px;
      padding: 60px 20px 26px 20px;
      margin-top: 44px;
      box-sizing: border-box;
      position: relative;
      border: 1px solid rgba(120, 132, 168, 0.3);
      border-radius: 3px;
      background: linear-gradient(360deg, #1c1e2b 0%, #1a1c2c 100%);

      .icon {
        position: absolute;
        width: 52px;
        height: 52px;
        top: -26px;
        left: 20px;
      }

      h1 {
        height: 32px;
        font-family: ANB;
        font-size: 16px;
        color: #ffffff;
        line-height: 16px;
      }

      h2 {
        margin-top: 18px;
        font-size: 14.5px;
        font-family: ANM;
        font-weight: 500;
        color: #9aaed1;
        line-height: 16px;
      }
    }
  }
  .architecture {
    padding: 0 16px;
    margin-top: 120px;
    .pic {
      margin-top: 30px;
      width: 100%;
      height: 100%;
    }
    &-box {
      margin-top: 33px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .p1 {
      width: 100%;
      font-size: 14px;
      font-family: ANM;
      font-weight: 500;
      color: #ffffff;
      line-height: 16px;
    }
    .arrow {
      width: 100%;
      text-align: center;

      img {
        width: 24px;
        height: 100%;
        margin: 28px auto;
        transform: rotate(90deg);
      }
    }
  }
  .service {
    margin-top: 120px;
    padding: 0 16px;
    p {
      margin-top: 32px;
      font-size: 14px;
      font-family: ANM;
      font-weight: 500;
      color: #ffffff;
      line-height: 19px;
    }

    .pic {
      margin-top: 32px;
      width: 100%;
      height: 100%;
    }
  }
  .advantages {
    padding: 50px 16px;
    &-box {
      margin-top: 32px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      width: 100%;
      height: 240px;
      margin: 5px 0;
      background: linear-gradient(
        141deg,
        rgba(62, 64, 85, 0.5) 0%,
        rgba(43, 47, 80, 0.5) 100%
      );
      border: 1px solid rgba(171, 233, 243, 0.5);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        width: 46px;
        height: 46px;
        margin-bottom: 32px;
        margin-top: 30px;
      }

      h1 {
        padding: 0 20px;
        text-align: center;
        font-size: 34px;
        font-family: ANM;
        font-weight: 600;
        color: #fff;
        line-height: 19px;
        margin-top: 14px;
      }

      h2 {
        padding: 0 20px;
        text-align: center;
        font-size: 12px;
        font-family: ANM;
        font-weight: 600;
        color: #9aaed1;
        line-height: 19px;
        margin-top: 34px;
      }
    }
  }
  .stack {
    padding: 50px 16px;
    .stack-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .remark {
      width: 100%;
      margin-top: 30px;

      p {
        background: linear-gradient(to right, #00ffbc, #2073ff);
        background-clip: text;
        -moz-background-clip: text;
        -ms-background-clip: text;
        -moz-background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        line-height: 20px;
      }

      .p1 {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
      }
    }

    .pic {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      img {
        width: 80%;
        height: 80%;
      }
    }
  }
  .roadmap {
    margin-top: 20px;
    padding: 50px 16px;
    ul {
      margin-top: 32px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      position: relative;
      width: 50%;
      margin-bottom: 30px;
    }
    img {
      width: 100%;
      height: 125px;
    }
    .sub-title {
      height: 25px;
      line-height: 25px;
      position: absolute;
      top: 0px;
      right: 35px;
      font-size: 12px;
      font-family: ANB;
      color: #cddaff;
    }
    .phase {
      display: flex;
      img {
        width: 10px;
        height: 10px;
        margin-top: 5px;
      }
      h1 {
        margin-top: 10px;
        margin-left: 10px;
        font-size: 16px;
        font-family: ANB;
        color: #ffffff;
        line-height: 20px;
      }
      h2 {
        width: 155px;
        margin-left: 10px;
        margin-right: 5px;
        font-size: 12px;
        font-family: ANM;
        font-weight: 500;
        color: #9aaed1;
        line-height: 20px;
      }
    }
  }
  .bg2 {
    background: radial-gradient(180px, rgba(18, 38, 57, 1) 0%, #0a1016 200%);
  }
  .map {
    width: 100%;
    height: 100%;
    margin: 15px 0 50px;
  }
  .footer-nav {
    width: 100%;
    margin: 40px auto;

    .ul-nav {
      display: none;
      justify-content: center;
    }
    .ul-nav-m {
      text-align: center;
      & > li {
        margin-bottom: 60px;
      }
    }

    h1 {
      font-size: 14px;
      font-family: ANB;
      color: #ffffff;
    }

    .ul-li-nav {
      margin-top: 32px;
      li {
        width: 100%;
        margin-bottom: 20px;
        font-size: 12px;
        font-family: ANM;
        font-weight: 500;
        color: #9aaed1;
        &.active {
          color: #fff;
          text-shadow: 0px 0px 15px #4790f0;
        }
      }
    }
  }
}
@media screen and (min-width: 429px) and(max-width:1024px) {
  .bg {
    // background: url("../assets/img/head1.png") no-repeat right 0;
    // background-size: 50%;
    background: #0a0f15;
  }
  .content {
    height: 100%;
    background: url("../assets/img/head-bg.png") no-repeat center 0;
    background-size: 100%;
    padding-top: 80px;
    overflow-y: scroll;
  }
  .title {
    width: 100%;
    font-size: 24px;
    font-family: ANB;
    font-weight: bold;
    color: #ace8f2;
    line-height: 32px;
  }
  p {
    font-size: 14px;
    line-height: 20px;

    &.p1 {
      margin-top: 16px;
    }
  }
  .bg-wave {
    background: url("../assets/img/breakline.png") no-repeat 0 0;
    background-size: 100%;
    padding-top: 86px;
  }
  .head {
    margin-top: 10px;
    margin-left: 48px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    height: 80vh;
    background: url("../assets/img/head-new-bak.png") no-repeat right bottom;
    background-size: 60%;
    position: relative;
    .remark {
      position: absolute;
      width: 100%;
      bottom: 10%;
      h1 {
        font-size: 38px;
        font-family: "ANB";
        line-height: 39px;
      }
      .h1-box {
        font-weight: bold;
      }
      .p-box {
        margin-top: 30px;
        width: 100%;
      }
      .website {
        display: flex;
        align-items: center;
        margin-top: 25px;
        li {
          cursor: pointer;
          width: 25px;
          height: 21px;
          margin: 0 16px 0 0;
          .github {
            width: 23px;
          }
        }
      }
    }
    // .pic {
    //   width: 80%;
    //   height: 100%;
    //   margin-top: 300px;
    // }
  }
  .apis {
    margin-top: 10px;
    padding: 0 48px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .remark {
      width: 50%;
      margin-top: 34px;
      margin-right: 0px;

      h1 {
        font-size: 32px;
        font-family: "ANB";
        line-height: 44px;
      }

      .p2 {
        margin-top: 30px;
      }

      .p-box {
        margin-top: 32px;
      }
    }
    .pic {
      width: 50%;
      height: 100%;
      margin-top: 100px;
      margin-left: 10px;
    }
  }
  .protocol {
    margin-top: 120px;
    padding: 0 48px;
    p {
      margin-top: 32px;
      font-size: 16px;
      font-family: ANM;
      font-weight: 500;
      color: #ffffff;
      line-height: 24px;
      text-align: center;
    }

    .pic {
      margin-top: 32px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 80%;
      }
    }
  }
  .blockchain {
    padding: 0 48px;
    padding-top: 100px;
    .blockchain-box {
      display: flex;
      justify-content: center;
    }

    .remark {
      width: 45%;
      margin-right: 4%;
      margin-top: 60px;
    }

    .pic {
      width: 50%;
      height: 100%;
      margin-top: 20px;
    }
  }
  .indexing {
    margin-top: 200px;
    .title {
      margin-left: 48px;
      margin-right: 48px;
    }
    ul {
      margin-top: 35px;
      display: flex;
      justify-content: flex-start;
      margin-left: 48px;
      margin-right: 48px;
    }

    li {
      display: flex;
      align-items: center;
      margin-right: 35px;

      img {
        width: 19px;
        height: 19px;
      }

      p {
        margin-left: 20px;
        font-size: 16px;
        font-family: ANM;
        font-weight: 500;
      }
    }
    .pic {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      // width: 1200px;
      // height: 497px;
      // width: 100%;
      // height: 100%;
      img {
        width: 88%;
      }
    }
  }
  .product {
    padding: 0 48px;
    .title {
      margin-top: 120px;
      margin-bottom: 70px;
    }
    ul {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    li {
      width: 49%;
      height: 470px;
      padding: 58px 20px 0 20px;
      margin-top: 4px;
      box-sizing: border-box;
      position: relative;
      border: 1px solid rgba(120, 132, 168, 0.3);
      border-radius: 8px;
      background: linear-gradient(360deg, #1c1e2b 0%, #1a1c2c 100%);

      .icon {
        position: absolute;
        width: 52px;
        height: 52px;
        top: -26px;
        left: 20px;
      }

      h1 {
        font-family: ANB;
        font-size: 16px;
        color: #ffffff;
        line-height: 16px;
      }

      h2 {
        margin-top: 14px;
        font-size: 14px;
        font-family: ANM;
        font-weight: 500;
        color: #9aaed1;
        line-height: 26px;
      }
    }
  }
  .architecture {
    padding: 0 48px;
    margin-top: 120px;
    .pic {
      margin-top: 30px;
      width: 100%;
      height: 100%;
    }
    &-box {
      margin-top: 33px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .p1 {
      width: 100%;
      font-size: 14px;
      font-family: ANM;
      font-weight: 500;
      color: #9aaed1;
      line-height: 24px;
    }
    .arrow {
      width: 100%;
      text-align: center;

      img {
        width: 20px;
        height: 100%;
        margin: 20px auto;
        transform: rotate(90deg);
      }
    }
  }
  .service {
    margin-top: 120px;
    padding: 0 48px;
    p {
      margin-top: 32px;
      font-size: 14px;
      font-family: ANM;
      font-weight: 500;
      color: #9aaed1;
      line-height: 24px;
    }

    .pic {
      margin-top: 32px;
      width: 100%;
      height: 100%;
    }
  }
  .advantages {
    margin-top: 100px;
    .title {
      margin-left: 48px;
      margin-right: 48px;
    }

    &-box {
      margin-top: 50px;
      margin-left: 48px;
      margin-right: 48px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      width: 100%;
      height: 392px;
      border: 1px solid #7884a8;
      border-radius: 4px;
      background: linear-gradient(360deg, #1c1e2b 0%, #1a1c2c 100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      margin-top: 24px;

      &:hover {
        background: rgba(131, 159, 231, 0.2);
        transition: background 0.3s ease;
      }

      .icon {
        width: 76px;
        height: 76px;
        margin-bottom: 28px;
        margin-top: 40px;
      }

      h1 {
        text-align: center;
        font-size: 56px;
        font-family: ANM;
        font-weight: 600;
        color: #fff;
        line-height: 25px;
        margin-top: 64px;
      }

      h2 {
        text-align: center;
        font-size: 14px;
        font-family: ANM;
        font-weight: 600;
        color: #9aaed1;
        line-height: 25px;
        margin-top: 48px;
      }
    }
  }
  .stack {
    .title {
      margin-left: 48px;
      margin-right: 48px;
      margin-top: 120px;
    }
    .stack-box {
      display: flex;
      justify-content: space-between;
      margin-left: 48px;
    }
    .remark-box {
      display: flex;
      flex-direction: column;
      .remark {
        padding-top: 20px;
        width: 294px;

        p {
          font-size: 16px;
          background: linear-gradient(to right, #00ffbc, #2073ff);
          -webkit-background-clip: text;
          color: transparent;
        }
  
        .p1 {
          margin-top: 20px;
          font-size: 14px;
          color: #fff;
        }
      }
    }

    .pic {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      img {
        width: 60%;
      }
    }
  }
  .roadmap {
    margin-top: 120px;
    padding: 0 48px;
    ul {
      margin-top: 32px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      position: relative;
      width: 25%;
      margin-bottom: 30px;
    }
    img {
      width: 90%;
      height: auto;
    }
    .phase {
      display: flex;
      img {
        width: 10px;
        height: 10px;
        margin-top: 16px;
      }
      h1 {
        margin-top: 10px;
        margin-left: 10px;
        font-size: 16px;
        font-family: ANB;
        color: #ffffff;
        line-height: 24px;
      }
      h2 {
        width: 155px;
        margin-top: 8px;
        margin-left: 10px;
        font-size: 14px;
        font-family: ANM;
        font-weight: 500;
        color: #9aaed1;
        line-height: 24px;
      }
    }
    .sub-title {
      height: 40px;
      line-height: 24px;
      position: absolute;
      top: 0px;
      right: 50px;
      font-size: 16px;
      font-family: ANB;
      color: #cddaff;
    }
  }
  .bg2 {
    background: radial-gradient(200px rgba(18, 38, 57, 0.8) 0%, #0a1016 100%);
  }
  .map {
    width: 100%;
    height: 200px;
    margin: 15px 0 50px;
  }
  .footer-nav {
    width: 100%;
    padding: 0 20px;
    margin: 40px auto;
    box-sizing: border-box;

    .ul-nav {
      display: none;
      justify-content: center;
    }
    .ul-nav-m {
      text-align: center;
      & > li {
        margin-bottom: 60px;
      }
    }

    h1 {
      font-size: 14px;
      font-family: ANB;
      color: #ffffff;
    }

    .ul-li-nav {
      margin-top: 32px;
      li {
        width: 100%;
        margin-bottom: 20px;
        font-size: 12px;
        font-family: ANM;
        font-weight: 500;
        color: #9aaed1;
        &.active {
          color: #fff;
          text-shadow: 0px 0px 15px #4790f0;
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .title {
    width: 100%;
    font-size: 22px;
    font-family: ANB;
    color: #ace8f2;
    line-height: 32px;
  }
  .head {
    .remark {
      h1 {
        font-size: 28px;
        font-family: "ANB";
        line-height: 20px;
      }
      .h1-box {
        font-weight: bold;
      }
      .p-box {
        font-size: 14px;
        width: 100%;
        margin-top: 20px;
      }
      .website {
        display: flex;
        align-items: center;
        margin-top: 20px;
        li {
          cursor: pointer;
          width: 20px;
          height: 18px;
          margin: 0 16px 0 0;
        }
      }
    }
  }
}
</style>
