<template>
  <div :class="[{ 'sticky-head': menuStyle }, 'header-fixed']">
    <div class="container">
      <div class="header flex hidden-mobile">
        <div class="logo">
          <img src="../assets/img/logo.png" alt="frua logo" />
        </div>
        <ul class="menu flex">
          <li
            v-for="item in menuList"
            :key="item.id"
            :class="{ active: activeBtn == item.id }"
            v-scroll-to="{ el: '#' + item.id, offset: myOffset }"
          >
            {{ item.name }}
          </li>
          <li class="doc">Documentation</li>
        </ul>
      </div>
      <div :class="[{ 'sticky-head': menuStyle }, 'header-h5 hidden-pc']">
        <div class="logo-m">
          <img src="../assets/img/logo.png" alt="frua logo" />
        </div>
        <div class="menu-btn">
          <img
            src="../assets/img/btn.png"
            alt="frua logo"
            @click="openMenu"
            :class="[dropdownMenu ? 'menu-icon-rotate' : 'menu-icon']"
          />
          <transition name="slide-fade">
            <ul
              class="dropdown-menu"
              v-if="dropdownMenu"
              @click.stop="handlerClickApp"
            >
              <li
                v-for="item in menuListMobile"
                :key="item.id"
                :class="{ active: activeBtn == item.id }"
                v-scroll-to="{ el: '#' + item.id, offset: myOffset }"
              >
                {{ item.name }}
              </li>
              <li>Documentation</li>
            </ul>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropdownMenu: false,
      menuList: [
        { name: "Problem", id: "apis" },
        { name: "Indexing 2.0", id: "indexing" },
        { name: "Advantages", id: "product" },
        { name: "Roadmap", id: "roadmap" },
      ],
      menuListMobile: [
        { name: "Problem", id: "apis" },
        { name: "Indexing 2.0", id: "indexing" },
        { name: "Advantages", id: "product" },
        { name: "Roadmap", id: "roadmap" },
      ],
    };
  },
  props: {
    menuStyle: {
      type: Boolean,
      default: false,
    },
    activeBtn: {
      type: String,
      default: "apis",
    },
    client: {
      type: String,
      default: "",
    },
  },
  components: {},
  computed: {
    myOffset: function () {
      return this.client == "pc" ? -160 : -70;
    },
  },
  methods: {
    openMenu() {
      this.dropdownMenu = !this.dropdownMenu;
    },
    handlerClickApp() {
      this.dropdownMenu = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1400px) {
  .header-fixed {
    width: 100%;
    position: fixed;
    transition: 0.3s;
    z-index: 99;
    .header {
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      .logo {
        width: 49px;
        height: 56px;
        img {
          display: block;
          width: 100%;
        }
      }
      .menu {
        li {
          margin-left: 44px;
          font-size: 18px;
          color: rgba(255, 255, 255, 0.7);

          &:hover {
            color: #fff;
            cursor: pointer;
            text-shadow: 0px 0px 15px #1e77fc;
          }

          &.active {
            color: #fff;
            text-shadow: 0px 0px 15px #1e77fc;
          }
        }
        .doc {
          color: #fff;
          padding: 4px 20px;
          border-radius: 8px;
          background: linear-gradient(90deg, #33d7d9, #08c1f3);
        }
      }
    }
  }
  .header-fixed.sticky-head {
    background: #0f0f16;
    border-bottom: 1px solid rgba(245, 245, 245, 0.1);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.9);
    .header {
      padding: 16px 0;
      transition: all 0.3s ease-in;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.menu-icon {
  transition: all 0.5s;
}
.menu-icon-rotate {
  transform: rotate(90deg);
  transition: all 0.5s;
}
@media screen and (max-width: 428px) {
  .header-h5 {
    width: 100%;
    height: 52px;
    padding: 0 18px 0 12px;
    box-sizing: border-box;
    // background: linear-gradient(225deg, #05ebc6 0%, #1f79fc 100%);
    position: fixed;
    top: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu-btn {
      width: 20px;
      height: 18px;
    }

    .logo-m {
      width: 33px;
      height: 37px;
    }
  }
  .header-h5.sticky-head {
    background: #0f0f16;
    border-bottom: 1px solid rgba(245, 245, 245, 0.1);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.9);
    .header {
      padding: 22px 0;
      transition: all 0.3s ease-in;
    }
  }
  .dropdown-menu {
    width: 100%;
    background: #1a1d28;
    padding: 0 16px;
    position: absolute;
    top: 52px;
    left: 0;
    li {
      padding: 13px 0;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #bccde3;
      border-bottom: 1px solid #607a8f;
    }
  }
}
@media screen and (min-width: 415px) and(max-width:1024px) {
  .header-h5 {
    width: 100%;
    height: 52px;
    padding: 0 18px 0 12px;
    box-sizing: border-box;
    // background: linear-gradient(225deg, #05ebc6 0%, #1f79fc 100%);
    position: fixed;
    top: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu-btn {
      width: 20px;
      height: 18px;
    }

    .logo-m {
      width: 33px;
      height: 37px;
    }
  }
  .header-h5.sticky-head {
    background: #0f0f16;
    border-bottom: 1px solid rgba(245, 245, 245, 0.1);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.9);
    .header {
      padding: 22px 0;
      transition: all 0.3s ease-in;
    }
  }
  .dropdown-menu {
    width: 100%;
    background: #0b0f14;
    padding: 0 16px;
    position: absolute;
    top: 52px;
    left: 0;
    li {
      padding: 13px 0;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #bccde3;
      border-bottom: 1px solid #607a8f;
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  // .header-fixed {
  //   .header {
  //     .menu {
  //       width: 100%;
  //       justify-content: flex-end;
  //       li {
  //         margin-left: 44px;
  //         font-size: 18px;
  //       }
  //       .doc {
  //         color: #fff;
  //         padding: 4px 20px;
  //         border-radius: 4px;
  //         background: linear-gradient(90deg, #33d7d9, #08c1f3);
  //       }
  //     }
  //   }
  // }
  .header-fixed {
    width: 100%;
    position: fixed;
    transition: 0.3s;
    z-index: 99;
    .header {
      padding: 16px 20px;
      display: flex;
      justify-content: space-between;
      .logo {
        width: 49px;
        height: 56px;
        img {
          display: block;
          width: 100%;
        }
      }
      .menu {
        width: 100%;
        justify-content: flex-end;
        li {
          margin-left: 44px;
          font-size: 18px;
          color: rgba(255, 255, 255, 0.7);

          &:hover {
            color: #fff;
            cursor: pointer;
            text-shadow: 0px 0px 15px #1e77fc;
          }

          &.active {
            color: #fff;
            text-shadow: 0px 0px 15px #1e77fc;
          }
        }
        .doc {
          color: #fff;
          padding: 4px 20px;
          border-radius: 4px;
          background: linear-gradient(90deg, #33d7d9, #08c1f3);
        }
      }
    }
  }
  .header-fixed.sticky-head {
    background: #0f0f16;
    border-bottom: 1px solid rgba(245, 245, 245, 0.1);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.9);
    .header {
      padding: 16px 0;
      transition: all 0.3s ease-in;
    }
  }
}
</style>
