import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: localStorage.getItem('locale') || 'en',
  },
  mutations: {
    CHANGELANGUAGE(state, status) {
      state.language = status;
      localStorage.setItem('locale', status)
    },
  },
  actions: {},
  modules: {},
});