export default {
  home: {
    aPIsFor: "APIs for",
    nextGeneration: "Next Generation",
    headTitle: "FURA",
    headDesc: "APIs for Web3",
    problemTitle: "Problem",
    problemDesc1:
      "Web3 Apps exploded in the past two years, so did onchain data. In the new multichain paradigm, Apps are deployed across multiple blockchains and Layer 2 platforms, now onchain data is produced at 100x of the speed and volume compared to before. Onchain data starts to exhibit big data characteristics.",
    problemDesc2:
      "The data engineering scenarios like index, analytics, search, notify is exploding, the level of difficulty of handling such scenarios are elevated, yet the existing solutions on the market are struggling to fulfill the demand.",
    protocolTitle: "Fura Protocol",
    protocolDesc1: "Fura is a high performance data protocol built for Web3",
    protocolDesc2:
      "Data Layer Protocols is to Web3 as AWS is to Web2, as Mining Pools to PoW Blockchains",
    dataIndexTitle: "Cambrian Explosion of Data",
    dataIndexDesc1:
      "In Web3, high data speed & volume applications like Metaverse, GameFi, and DeFi naturally demand a High performance blockchain data infrastructure, data protocols is a critical lego piece in Web3 to deliver product and services.",
    dataIndexDesc2:
      "Web 2 data analytics market alone is $100 billion now. With the critical role it plays, Web3 data market size will be at least 10x of Web2.",
    product: "Core Advantages",
    productSub1: "Parallelization Across All Stages",
    productSub2: "Real Time Processing",
    productSub3: "High Consistency",
    productSub4: "Fast Playback ",
    productSubDesc:
      "Fura index engine processes data in stages, namely extracting, parsing, processing, and merging. Each stage is parallelized, The load capacity can simply be expanded by adding more nodes, In theory, the capacity is able to horizontally scale to infinity.",
    productSubDesc2:
      "Stream processing is a great fit dealing with high speed continuously blockchain data, In real time, raw transactions forms multiple unbounded streams composed of a continuous sequence of events goes through operators to generates processed data streams, each operator can be parallelized to process a theoretically infinite number of tasks.",
    productSubDesc3:
      "The events are divided into time slices so that processing is in approximate order. Sequenced events in the same shard tranform into multiple messages after index logic operator, while processing, every message is assigned a hash to indicate the global sequencing. In this way, different message types can be processed in parallel, and the same types of messages can be processed in sequenced manner.",
    productSubDesc4:
      "In indexing, full sync from block zero is usually more frequent that we think. Therefore, Fura keeps a parsed data sink for fast playback scenarios. significant time is saved since there is no need to re-pull data from the blockchain node.",
    indexing: "Fura is Indexing 2.0",
    indexingDesc1: "Inherently Scalable",
    indexingDesc2: "Always On",
    indexingDesc3: "No Single Point of Failure",
    indexingDesc4: "Partition Tolerant",
    architecture: "High TPS Index Architecture",
    architectureDesc1:
      "block and transaction data first goes to Message Queue then get distributed to stream processing cluster.",
    architectureDesc2:
      "Stream engine processes message in real time based on the user defined index l ogic, in parallel, the serialized raw data is pushed to the HDFS sink for backup, reconciliation and other purposes",
    architectureDesc3:
      "Indexed results will then be pushed to both Cassandra and elastic search, results data then will be served based on the defined requirements, being API endpoints, search request or other more complex query requirements.",
    service: "Service",
    serviceDesc:
      "On-chain data exploded with the growth of DeFi Apps. With interoperability, DApps are deployed across multiple chains and L2 platforms, now on-chain data is produced at 10x or even 100x the volume compared to before. On- chain data starts to exhibit big data characteristics.",
    adv: "Performance Live",
    adv1: "Events indexed and growing",
    adv2: "Tnx indexed and growing",
    adv3: "API requests served per month",
    stack: "Product Stack",
    stackSub1: "Index Engine",
    stackSub2: "Stream",
    stackSub3: "Search",
    stackSub4: "Push Notifcation",
    stackDesc1:
      "The core scalable engine that extract, parse, process and merge data.",
    stackDesc2:
      "Streaming framework will allow developers to create user defined data streaming logic and serve the APIs in the from of unbounded data streams to thousands of Web3 Apps downstream.",
    stackDesc3:
      "Search Framework will utilize the data sink and allow developers to perform ad-hoc big queries against the data sink across multiple chains and L2s.",
    stackDesc4:
      "Push Framework will allows developers to create user defined alerts and subscribe to specific events based predefined on onchain signals at scale.",
    roadMap: "Roadmap",
    roadMapF1: "Phase Ⅰ",
    roadMapF2: "Phase Ⅱ",
    roadMapF3: "Phase Ⅲ",
    roadMapF4: "Phase Ⅳ",
    roadMapF1Desc1: "Foundation Formation",
    roadMapF1Desc2: "Seed Round",
    roadMapF1Desc3: "Index Engine Design",
    roadMapF1Desc4: "Alpha Cluster Build",
    roadMapF2Desc1: "Alpha Cluster Release",
    roadMapF2Desc2: "Streaming Pilot on Polygon",
    roadMapF2Desc3: "Strategic Round",
    roadMapF2Desc4: "Streaming Framework Design",
    roadMapF2Desc5: "Push Framework Design",
    roadMapF3Desc1: "Fura Protocol Yellow Paper Release",
    roadMapF3Desc2: "Beta Cluster Release",
    roadMapF3Desc3: "Push Notification Pilot",
    roadMapF3Desc4: "Support other EVM and L2 chains",
    // roadMapF3Desc5: "TGE",
    roadMapF4Desc1: "Index Engine Beta Release",
    roadMapF4Desc2: "Subgraph Converter Release",
    roadMapF4Desc3: "Cluster Release",
    roadMapF4Desc4: "Official Hosted Service Release",
    roadMapF4Desc5: "Official Deployer SDK Release",
  },
};
