import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocale from "./en_us";
import zhLocale from "./zh_cn";
Vue.use(VueI18n);
const messages = {
  en: {
    ...enLocale,
  },
  zh: {
    ...zhLocale,
  },
};

const i18n = new VueI18n({
  locale: localStorage.getItem("locale") || "en", // 设置默认语言
  messages: messages,
});

localStorage.setItem("locale", i18n.locale);

export {
  i18n
};