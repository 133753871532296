<template>
  <div class="footer container">
    <p class="copyright">© 2021 Fura</p>
    <div class="link">
      <ul class="nav">
        <li><router-link to="/privacy-policy">Privacy</router-link></li>
        <!-- <li><router-link to="">Terms of use</router-link></li> -->
        <!-- <li><Language></Language></li> -->
      </ul>
      <ul class="website">
        <li>
          <a href="https://t.me/fura_community" target="_blank"
            ><img src="../assets/img/telegram.png" alt="telegram"
          /></a>
        </li>
        <li>
          <a href="https://github.com/fura-protocol" target="_blank"
            ><img class="github" src="../assets/img/github.png" alt="twitter"
          /></a>
        </li>
        <li>
          <a href="https://twitter.com/fura_protocol" target="_blank"
            ><img src="../assets/img/twitter.png" alt="twitter"
          /></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import Language from "./Language.vue";
export default {
  data() {
    return {};
  },

  // components: { Language },

  computed: {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.footer {
  background: #0f0f16;
  padding: 30px 48px;
  display: flex;
  justify-content: space-between;

  .link {
    display: flex;
    align-items: center;
    .website {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        cursor: pointer;
        width: 25px;
        height: 21px;
        margin: 0px 20px;

        .github {
          width: 23px;
        }
      }
    }
    .nav {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        margin: 0 20px;
      }
      a {
        font-size: 18px;
        font-family: ANB;
        font-weight: 400;
        color: #fff;
        text-decoration: none;
      }
    }
  }
  p {
    text-align: center;
    font-size: 18px;
    font-family: ANB;
    font-weight: 400;
    color: #fff;
  }
}
@media screen and (max-width: 414px) {
  .footer {
    padding: 30px 20px;
    align-items: center;

    p {
      font-size: 14px;
    }
    .link {
      .website {
        li {
          width: 19px;
          height: 15px;
          margin: 0px 10px;
          .github {
            width: 17px;
          }
        }
      }
      .nav {
        li {
          margin: 0 10px;
        }
        a {
          font-size: 14px;
        }
      }
    }
  }
}
</style>